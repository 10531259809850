body{
background-color: #F5F5F7;
overflow-x: hidden;
}

/* Add this to your CSS */

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1); /* Adjust scale value as needed */
    opacity: 1; /* Adjust opacity as needed */
  }
}

.main-home-header {
  max-width: 100%;
  width: 98%;
  margin: 0 auto;
  height: 110vh;
  background-image: url("../images/orignal/webp/Hafiz-1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  animation: zoomOut 0.3s forwards; /* Trigger the animation */
  overflow: hidden;
  z-index: 10;
}
.main-other-pages{
  max-width: 100%;
  width: 98%;
  margin: 0 auto;
  height: 70vh;
  background-image: url("../images/orignal/webp/Hafiz-1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  animation: zoomOut 1s forwards; /* Trigger the animation */
  overflow: hidden;
  z-index: 10;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



@media (max-width:1440px) {
  .main-home-header {
    background-position: right;
    height: 80vh;
  }
  .main-other-pages{
    background-position: right;
    height: 50vh;
  }
}

@media (max-width:1280px) {
  .main-home-header {
    background-position: right;
    height: 100vh;
  }
  .main-other-pages{
    background-position: right;
    height: 70vh;
  }
}

@media (max-width:768px) {
  .main-home-header {
    background-position: right;
    height: 80vh;
  }
  .main-other-pages{
    background-position: center;
    height: 50vh;
  }

}
@media (max-width:400px) {
  .main-home-header {
    background-position: right;
    height: 70vh;
  }
  .main-other-pages{
    background-position: center;
    height: 45vh;
  }

}
.header-image {
  background-image: url("../images/Mask_BXZVD9.webp");
  background-repeat: no-repeat;
  background-size: cover;
  width: 28rem;
  height: 14rem;
}

  
  /* Glassy effect */
  .backdrop-blur-md {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
  
  /* Ensure the header stays at the top */
  .sticky {
    position: sticky;
    top: 0;
    width: 100%;
   
  }
  

  .transition-opacity {
    transition: opacity 0.5s ease-in-out;
  }

  



  /* App.css */
.video-element {
    transition: width 0.7s ease, opacity 0.7s ease;
}

.video-thumbnail {
    width: 80%;
    opacity: 0.2;
}

.video-fullscreen {
    width: 100%;
    opacity: 1;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 20;
}

.fade-in {
    opacity: 1 !important;
}

.fade-out {
    opacity: 0 !important;
}



/* footer     */

.footer-image{
  background-image: url('../images/footer/business-footer-cta.webp');
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 4rem;
  height: 450px;
  border-radius: 10px;
  background-color: black;
}
@media (max-width:768px) {
  .footer-image{
    margin-right: 0;
    height: 300px;
  }
}



.on-the-go{
  background-image: url('../images/Header/on-go-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.at-home{
  background-image: url('../images/Header/mm-bg-home-2.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}